/* You can add global styles to this file, and also import other style files */
@import '~@intersystems/styles/css/core-styles';
@import 'header-styles';
@import '~@intersystems/styles/css/layout-wrapper-mat-dialog-panel';
//@import '@intersystems/isc-form/assets/styles/isc-form-modal-panel.scss';
@import '@intersystems/isc-form/assets/styles/variables.scss';

.isc-form-container {
  height: initial !important;
}

body,
html {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-height: 100%;
}

a {
  color: var(--fr-styles-color-primary);
  cursor: pointer;
}

// Fix styling issues in paginator
mat-paginator {
  .mat-form-field-wrapper {
    padding: 0;
  }
  .mat-paginator-page-size-select {
    margin: 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0 8px 0;
  }
  .mat-select-arrow-wrapper {
    transform: translateY(10%) !important;
  }
  .mat-paginator-container {
    min-height: 40px;
  }

  @media screen and (max-width: 400px) {
    .mat-paginator-range-actions {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 4fr;
      flex-wrap: wrap;

      .mat-paginator-range-label {
        grid-column: 1/5;
        order: 1 !important;
        margin: 0 4px;
      }
    }
  }

  @media (min-width: 319px) and (max-width: 341px) {
    button:nth-child(2) {
      margin-left: 100px;
    }
  }
}
.paginator-container .paginator-jump-container {
  top: 9px !important;
}

// Fix mobile menu
.mobile-dropdown-container {
  width: 300px;
}

// Fix Mat-tabs pagination; see https://github.com/angular/components/issues/23157
.mat-tab-group {
  max-width: 100%;
}

.form-body {
  max-width: 550px;
  overflow: hidden;
}

.form-body-mobile {
  padding: 2px !important;
  margin: -2px !important;
  max-width: 300px !important;
  overflow-y: unset !important;
}

// Fix scrolling issue on mobile forms
.form-body-mobile formly-group .field-container {
  padding: 0 !important;
}

// Fix extra space above buttons in footer of mobile forms
.form-footer-mobile .footer-lh {
  display: none;
}

form {
  position: relative;
}

formly-field {
  display: grid;
  grid-gap: 5px;
  word-break: break-word;
  text-align: justify;
}

// View mode for review step
.review-step .form-body {
  overflow: unset;
  max-width: unset;
  formly-field {
    margin-top: 0.5rem;
    display: block;
  }
  .field-container {
    flex-direction: row !important;
    margin: 0 !important;

    mat-label {
      width: 300px;
      white-space: nowrap;
    }

    p {
      margin: 0 !important;
    }

    mat-hint {
      display: none;
    }
  }
  .wrapper,
  .field {
    display: flex;
    width: inherit;
  }
}

// Fix extra space in search fields and table headers
.table-header mat-form-field:not(.mat-paginator-page-size-select) {
  margin: 0;
}

// FIX SCROLLING ISSUE ON FR-TABLE
fr-table {
  box-sizing: border-box;
}
app-loading-spinner .mat-spinner {
  margin: -15px 0px 20px 20px;
}

// Fix some table styles
fr-table {
  .mat-table tr th,
  .mat-table tr td {
    vertical-align: middle;
  }
}

@media only screen and (max-width: 376px) {
  .paginator-container .paginator-jump-container {
    right: 135px !important;
  }
  fr-table {
    .table-header {
      width: 100%;
      flex-wrap: wrap;
      justify-content: start;

      .table-header-right {
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
  .table-header .search-box-container {
    margin: 0 0rem !important;
  }
}

@media only screen and (max-width: 321px) {
  .mat-paginator {
    button {
      margin-left: -9px;
    }
  }
  .paginator-container .paginator-jump-container {
    right: 100px;
  }
}

// Fix icon positioning in action rows
lib-actions-icons-cell .mat-icon-button .mat-button-wrapper > * {
  margin: 0px 0px 3px 0px;
}

// Make icons smaller in the table
lib-actions-icons-cell .mat-icon {
  height: 18px;
  width: 18px;
}

// Fix table search field position
fr-search-bar .mat-form-field-infix {
  margin-top: 3px;
}

// Universal global margin
.mt-16px {
  margin-top: 16px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mr-16px {
  margin-right: 16px !important;
}

.text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}

// Fix Angular Material Firefox issue
@-moz-document url-prefix() {
  .mat-drawer-content a .mat-button-wrapper {
    width: initial !important;
  }
}

/* Don't know why these class is not in the core-styles. Copied it from isc-form-harness application */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #f1f1f1;

  .close-modal-icon:hover {
    cursor: pointer;
  }
}
