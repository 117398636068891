:root {
  --fr-header-height-toolbar: 40px;
  --fr-header-border-top-toolbar: 4px;
  --fr-header-height-utility-menu-item: 40px;
  --fr-header-font-size-utility-menu-item: 13px;
  --fr-header-height-language-menu-item: 40px;
  --fr-header-font-size-language-menu-item: 13px;
}

// General styling for dropdown utility menu & dropdown language menu
// Is an overlay so needs to be imported into global styles.
.mat-menu-panel.fr-header-utility-menu,
.mat-menu-panel.fr-header-language-menu {
  width: 200px !important;
  border-radius: 0 !important;
  .mat-menu-content:not(:empty) {
    padding: 0 !important;
  }
  .mat-menu-item {
    height: unset !important;
    max-height: fit-content !important;
    display: flex;
    align-items: center;
    font-size: inherit !important;
    padding: 0 1.5rem !important;
  }
  .mat-menu-item:not(:last-child) {
    // All menu items (other than final one) should have border.
    // Leave off final one as the bottom part of the container is sufficient
    border-bottom: 1px solid var(--fr-styles-color-middle-grey) !important;
  }
  .mat-menu-item:focus {
    outline: 2px solid var(--fr-styles-color-state-focused) !important;
    outline-offset: -4px !important;
    background-color: transparent !important;
  }
  .mat-menu-item:hover:not([disabled]) {
    background-color: var(--fr-styles-color-state-selected) !important;
  }
}

// Specific styling for utility menu (including company logo)
.mat-menu-panel.fr-header-utility-menu {
  font-size: var(--fr-header-font-size-utility-menu-item) !important;
  .mat-menu-item {
    min-height: var(--fr-header-height-utility-menu-item) !important;
    .fr-header-utility-menu-item {
      // To overflow text onto new lines
      white-space: pre-line;
      line-height: normal !important;
    }
  }
  .fr-header-utility-menu-product-logo-container {
    height: calc(2 * var(--fr-header-height-utility-menu-item)) !important;
    .fr-header-utility-menu-product-logo {
      box-sizing: border-box;
      width: 100%;
      height: calc(1.5 * var(--fr-header-height-utility-menu-item)) !important;
    }
  }
}

// Specific styling for language menu
.mat-menu-panel.fr-header-language-menu {
  font-size: var(--fr-header-font-size-language-menu-item) !important;
  .mat-menu-item {
    min-height: var(--fr-header-height-language-menu-item) !important;
    .fr-header-language-menu-item {
      // To overflow text onto new lines
      white-space: pre-line;
      line-height: normal !important;
    }
  }
}
